import { useEffect, useRef, useMemo } from "react";
import ReactDOMServer from "react-dom/server";
import { SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { InformativeBlock } from "../InformativeBlock";
import { MainSwiper } from "../MainSwiper";
import { AssetDownloadBlock } from "../AssetDownloadBlock";
import styles from "./HorizontalProductSwipe.module.scss";
import { IconMonitor, IconSiren, IconHeater, IconFan } from "icons";

const paginationIcons = {
  0: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconMonitor
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  1: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconFan
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  2: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconHeater
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  3: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconMonitor
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  4: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconSiren
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  5: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconFan
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  6: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconHeater
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  7: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconSiren
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  8: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconMonitor
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  9: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconFan
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  10: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconHeater
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  11: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconMonitor
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  12: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconSiren
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  13: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconFan
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  14: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconHeater
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  15: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconSiren
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  16: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconMonitor
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  17: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconFan
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  18: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconHeater
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  19: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconMonitor
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  20: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconSiren
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  21: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconFan
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  22: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconHeater
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
  23: ReactDOMServer.renderToString(
    <div className={styles.iconBulletBlock}>
      <IconSiren
        width={24}
        heigh={24}
        color="black"
        className={styles.iconBullet}
      />
    </div>
  ),
};

export const HorizontalProductSwipe = ({
  slides = [],
  defaultActiveSwiperIdx,
  hasBulletsIcons,
}) => {
  const swiperRef = useRef(null);

  const firstEnhancedIndex = useMemo(() => {
    if (slides.some(item => !('enhancements' in item))) {
      return slides.findIndex((item, index) => item.enhancements === true)
    } 
    return null;
  }, [slides])


  const handleVideoPlay = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleVideoPause = () => {
    if (swiperRef.current) {
      swiperRef.current.autoplay.start();
    }
  };

  useEffect(() => {
    if (swiperRef.current) {
      if (defaultActiveSwiperIdx === null) {
        swiperRef.current.autoplay.start();
      } else {
        swiperRef.current.slideTo(defaultActiveSwiperIdx, 0);
        swiperRef.current.autoplay.stop();
      }
    }
  }, [defaultActiveSwiperIdx]);

  return (
    <MainSwiper
      ref={swiperRef}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{
        clickable: true,
        renderBullet: (index, className) => {
          const iconHtml = paginationIcons[index];
          if (iconHtml && hasBulletsIcons) {
            if (firstEnhancedIndex === index) {
              return `<span class="${className} slider-custom-icon first-enhance">
                <span class="${styles.divider}"></span>${iconHtml}
                <span class="${styles.enhTitle}">Enhancements</span>
              </span>`
            }

            if (firstEnhancedIndex - 1 === index) {
              return `<span class="${className} slider-custom-icon last-tech">${iconHtml}<span class="${styles.techTitle}">Main</span></span>`;
            }

            return `<span class="${className} slider-custom-icon">${iconHtml}</span>`;
          }
          return `<span class="${className}"></span>`;
        },
      }}
      modules={[Autoplay, Navigation, Pagination]}
      className={styles.swiper}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      {slides.map((slide, index) => {
        return (
          <SwiperSlide className={styles.swiperItem} key={index}>
            <InformativeBlock name={slide.name} text={slide.description} />
            {slide.type === "video" ? (
              <AssetDownloadBlock link={slide.content}>
                {!!slide.content && (
                  <video
                    onPlay={handleVideoPlay}
                    onPause={handleVideoPause}
                    className={styles.productItem}
                    controls
                  >
                    <source src={slide.content} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </AssetDownloadBlock>
            ) : (
              <AssetDownloadBlock link={slide.content}>
                {!!slide.content && (
                  <img
                    className={styles.productItem}
                    src={slide.content}
                    alt={`Slide ${index}`}
                  />
                )}
              </AssetDownloadBlock>
            )}
          </SwiperSlide>
        );
      })}
    </MainSwiper>
  );
};
