import { useRef, useState } from "react";
import { useTranslation } from "hooks/useTranslation";
import DropdownItem from "./components/DropdownItem";
import { ProductsDropdownContent } from "./components/ProductDropdownContent";
import { TooltipLikePopover } from "shared/components/TooltipLikePopover";
import { DynamicLink } from "shared/components/DynamicLink";
import { useDialog } from "hooks/useDialog";
import { useOutsideClick } from "hooks/useOutsideClick";
import { PrimaryDialog } from "shared/components/PrimaryDialog";
import { AboutUs } from "./components/AboutUs";
import { Locations } from "./components/Locations";
import styles from "./Header.module.scss";
import WhiteLogo from "assets/logos/white-logo.png";
import {
  IconStore,
  IconChevronDown,
  IconArrowDownToLine,
  IconInfoTransparent,
  IconLocationTransparent,
} from "icons";

const downloadBuildUrl = `${process.env.PUBLIC_URL}/build.zip`;

const DIALOG_COMPONENTS = {
  aboutUs: props => <AboutUs {...props} />,
  locations: props => <Locations {...props} />,
};

export const Header = ({ isScrolled }) => {
  const { translate } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [isOpenPopover, setIsOpenPopover] = useState(false);
  const [isOpenSectionsPopover, setIsOpenSectionsPopover] = useState(false);

  const sectionDialogRef = useRef(null);

  const sectionDialog = useDialog();

  useOutsideClick(
    sectionDialogRef,
    () => sectionDialog.closeDialog(),
    null,
    []
  );

  const technologyLinks = [
    { name: translate("fluxing") },
    { name: translate("preheating") },
    { name: translate("soldering") },
    { name: translate("other") },
  ];
  // const enhancementsLinks = [
  //   { name: translate("fluxing") },
  //   { name: translate("soldering") },
  // ];
  const marketSectorLinks = [
    { name: translate("Automotive/EV") },
    { name: translate("Contract Manufacturing (Tier 1-4)") },
    { name: translate("Aerospace") },
    { name: translate("Medical") },
    { name: translate("Commercial") },
    { name: translate("Hi-Rel/Infrastructure") },
  ];

  const sectionPopUps = [
    {
      name: translate("About Us"),
      icon: <IconInfoTransparent />,
      key: "aboutUs",
    },
    {
      name: translate("Locations"),
      icon: <IconLocationTransparent />,
      key: "locations",
    },
  ];

  const handleOpenPopover = () => setIsOpenPopover(true);

  const handleClosePopover = event => {
    event.stopPropagation();
    setIsOpenPopover(false);
  };

  const handleOpenSectionsPopover = () => setIsOpenSectionsPopover(true);

  const handleCloseSectionsPopover = event => {
    event.stopPropagation();
    setIsOpenSectionsPopover(false);
  };

  const handleClickToSec = (e, key) => {
    handleCloseSectionsPopover(e);
    sectionDialog.openDialog({ key });
  };

  return (
    <>
      <PrimaryDialog
        className={styles.sectionDialogPaper}
        blurBackdrop
        backdropColor={""}
        mode="dark"
        size="md-wide"
        radius="radius-20"
        open={sectionDialog.isOpen}
      >
        <div
          className={styles.sectionPopupContentContainer}
          ref={sectionDialogRef}
        >
          {!!sectionDialog.dialogData?.key &&
            DIALOG_COMPONENTS[sectionDialog.dialogData.key]({
              hasCloseIcon: true,
              handleClose: sectionDialog.closeDialog,
            })}
        </div>
      </PrimaryDialog>
      <div className={`${styles.header} ${isScrolled && styles.transparent}`}>
        <TooltipLikePopover
          anchorClassName={styles.logoTooltipAnchor}
          open={isOpenSectionsPopover}
          onClose={handleCloseSectionsPopover}
          onOpen={handleOpenSectionsPopover}
          anchorElement={
            <DynamicLink to="/" className={styles.logo}>
              <img src={WhiteLogo} alt="Pillar House" />
            </DynamicLink>
          }
        >
          {sectionPopUps.map((sp, idx) => (
            <div
              key={idx}
              queryParams={{ name: sp.name }}
              className={styles.popoverItem}
              onClick={e => handleClickToSec(e, sp.key)}
            >
              {sp.icon}
              {sp.name}
            </div>
          ))}
        </TooltipLikePopover>
        <nav className={styles.navigation}>
          <DropdownItem
            number={1}
            isOpen={openDropdown === 1}
            setIsOpen={setOpenDropdown}
            hasContentLeftPadding
            label={translate("technology")}
          >
            {technologyLinks.map((tl, idx) => (
              <DynamicLink
                key={idx}
                queryParams={{ name: tl.name }}
                to="/technology"
                className={styles.dropdownSecTitle}
                onClick={() => setOpenDropdown(null)}
              >
                {tl.name}
              </DynamicLink>
            ))}
          </DropdownItem>
          <DropdownItem
            number={2}
            isOpen={openDropdown === 2}
            setIsOpen={setOpenDropdown}
            label={translate("products")}
          >
            <ProductsDropdownContent
              closeDropdown={() => setOpenDropdown(null)}
            />
          </DropdownItem>
          {/* <DropdownItem
            number={3}
            isOpen={openDropdown === 3}
            setIsOpen={setOpenDropdown}
            hasContentLeftPadding
            label={translate("enhancements")}
          >
            {enhancementsLinks.map((el, idx) => (
              <DynamicLink
                key={idx}
                queryParams={{ name: el.name }}
                to="/enhancements"
                className={styles.dropdownSecTitle}
                onClick={() => setOpenDropdown(null)}
              >
                {el.name}
              </DynamicLink>
            ))}
          </DropdownItem> */}
        </nav>
        <TooltipLikePopover
          contentClassName={styles.marketTooltipContent}
          open={isOpenPopover}
          onClose={handleClosePopover}
          onOpen={handleOpenPopover}
          anchorElement={
            <div className={styles.marketSector}>
              <IconStore color="#fff" /> {translate("market sector")}{" "}
              <IconChevronDown color="#fff" />
            </div>
          }
        >
          {marketSectorLinks.map((msl, idx) => (
            <DynamicLink
              key={idx}
              queryParams={{ name: msl.name }}
              className={styles.popoverItem}
              to="/market"
              onClick={handleClosePopover}
            >
              {msl.name}
            </DynamicLink>
          ))}
        </TooltipLikePopover>
        <a
          href={downloadBuildUrl}
          download="pillarhouse.zip"
          className={styles.downloadItemContainer}
        >
          <IconArrowDownToLine />
        </a>
      </div>
    </>
  );
};
