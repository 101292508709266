import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EXISTING_KEYS,
  EXISTING_TECHNOLOGY_KEYS,
  FEATURES_INFOS,
  TECHNOLOGY_SLIDES,
} from "./constants";
import { InformativeBlock } from "shared/components/InformativeBlock";
import { HorizontalProductSwipe } from "shared/components/HorizontalProductSwipe";
import styles from "./Technology.module.scss";

export const Technology = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { search, state } = location;
  const queryParams = new URLSearchParams(search);
  const name = queryParams.get("name");
  const chosenItemOptName = queryParams.get("option");

  const [defaultActiveSwiperIdx, setDefaultActiveSwiperIdx] = useState(null);
  const horizontalSwiperRef = useRef(null);

  const contentKey = EXISTING_KEYS[name] || "default";
  const technologyContentKey = EXISTING_TECHNOLOGY_KEYS[name] || "default";

  useEffect(() => {
    if (state?.scrollToSwiper && horizontalSwiperRef.current) {
      horizontalSwiperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [state]);

  useEffect(() => {
    if (name && chosenItemOptName) {
      horizontalSwiperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      const selectingSwiperItemIdx = TECHNOLOGY_SLIDES[
        technologyContentKey
      ].findIndex(item => item?.option === chosenItemOptName?.toLowerCase());
      setDefaultActiveSwiperIdx(selectingSwiperItemIdx);
      navigate(location.pathname + location.search, {
        replace: true,
        state: {},
      });
    }
  }, [name, chosenItemOptName]);

  return (
    <div className={styles.container}>
      <p className={styles.welcomeText}>Welcome to Pillarhouse, your premier partner in soldering solutions for the dynamic electric vehicle (EV) industry. With unparalleled expertise and cutting-edge technology, we specialize in providing high-quality soldering services tailored specifically to the unique demands of electric vehicle manufacturing.</p>
      <div className={styles.infos}>
        {FEATURES_INFOS[contentKey].map((info, idx) => (
          <InformativeBlock key={idx} text={info} />
        ))}
      </div>
      <div ref={horizontalSwiperRef} className={styles.productSlideSec}>
        <HorizontalProductSwipe
          hasBulletsIcons
          defaultActiveSwiperIdx={defaultActiveSwiperIdx}
          slides={TECHNOLOGY_SLIDES[technologyContentKey]}
        />
      </div>
    </div>
  );
};
